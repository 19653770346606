.container {
    position: relative;
}

.row1 {
    width: 100%;
    height: 6.4rem;
    background-color: var(--white-color);
    border-radius: 0.5rem;
    display: flex;
    overflow: hidden;
}

.col1 {
    width: 54%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    position: relative;
}

.label {
    position: absolute;
    left: 2.2rem;
    top: 1rem;
    font-size: 1.3rem;
    color: var(--background-primary-main);
}

.input {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--background-primary-main);
    padding-top: 2.3rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
}

.input:focus {
    outline-width: 0;
}

.col2 {
    width: 46%;
    background-color: var(--white-color);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 2rem;
    padding-right: 2rem;
    color: var(--background-primary-main);
    justify-content: space-evenly;
    font-size: 1.3rem;
    min-width: 3.75rem;
}

.maxBtn {
    cursor: pointer;
}

.col21 {
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--background-primary-main);
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.6rem;
    cursor: pointer;
}

.expandIcon {
    width: 2.5rem;
    height: 2.5rem;
}

.tokenIcon {
    height: 1.6rem;
}

.options {
    border-radius: 0.5rem;
    position: absolute;
    background-color: var(--selector-bg);
    top: 6.5rem;
    right: 0;
    width: 40%;
    z-index: 2;

}

.option {
    height: 6rem;
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--background-primary-main);
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: 2rem;
    cursor: pointer;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.deactiveToken{
    color: var(--deactive-token);
}